<template>
  <!-- 右侧导航  -->
  <div class="navigation" v-show="showNavigation">
    <div
      class="right_navigation"
      v-for="(item, index) in items"
      :key="index"
      v-show="!isIndexFourHidden || index !== 4"
    >
      <div
        class="pic"
        @mouseenter="handleHover(index)"
        @mouseleave="handleLeave(index)"
      >
        <img :src="item.image" alt="" @click="handleClick(index)" />
      </div>
      <el-tooltip :visible="visible && index === 4" placement="left-start">
        <template #content>
          <span>返回上一页</span>
        </template>
        <div
          class="talk"
          style="
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: 13px;
            font-size: 16px;
            font-weight: 400;
          "
        >
          {{ item.talk }}
        </div>
      </el-tooltip>

      <div
        class="square"
        v-show="index == 2 && showQrCode"
        style="
          position: absolute;
          top: 50%;
          right: 46px;
          transform: translate(-50%, -50%);
        "
      >
        <div class="wx"></div>
        <div
          style="
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 16.46px;
            color: rgba(0, 0, 0, 1);
            position: absolute;
            bottom: 20px;
            left: 41px;
          "
        >
          添加 <span style="color: rgba(5, 187, 190, 1)">骅赢小哥哥</span> 微信
        </div>
      </div>
    </div>
  </div>
  <router-view />
</template>
<script setup>
import { ref, onMounted, onUnmounted, watchEffect, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
const router = useRouter();
const route = useRoute();
const visible = ref(false);

const isIndexFourHidden = ref(false);
isIndexFourHidden.value = route.path === "/";
watch(
  () => route.path,
  (newPath) => {
    isIndexFourHidden.value = newPath === "/";
  }
);
// 右侧导航
const showNavigation = ref(true); // 控制导航显示
const scrollPosition = ref(0); // 存储滚动位置
// 更新滚动位置的函数
const updateScrollPosition = () => {
  scrollPosition.value =
    window.pageYOffset || document.documentElement.scrollTop;
};
// 根据位置更新状态
const updateNavigation = () => {
  const t = 500; // 假设.section1的顶部偏移量是500px
  showNavigation.value = scrollPosition.value > t;
};
// 二维码
const showQrCode = ref(false);
const items = ref([
  { image: require("./assets/images/phone.png"), talk: "电话咨询" },
  { image: require("./assets/images/consulting.png"), talk: "在线咨询" },
  { image: require("./assets/images/agencies.png"), talk: "分支机构" },
  { image: require("./assets/images/top.png"), talk: "TOP" },
  { image: require("./assets/images/goHome.png"), talk: "上一页" },
]);
// 悬浮
const handleHover = (index) => {
  if (index === 0) {
    items.value[0].image = require("./assets/images/dh3.png");
  } else if (index === 1) {
    items.value[1].image = require("./assets/images/dh2.png");
  } else if (index === 2) {
    items.value[2].image = require("./assets/images/dh1.png");
    // console.log(showQrCode.value);
    showQrCode.value = true;
  } else if (index === 3) {
    items.value[3].image = require("./assets/images/dh4.png");
  } else if (index === 4) {
    items.value[4].image = require("./assets/images/dh5.png");
    visible.value = true;
  }
};
// 离开
const handleLeave = (index) => {
  if (index === 0) {
    items.value[0].image = require("./assets/images/phone.png");
  } else if (index === 1) {
    items.value[1].image = require("./assets/images/consulting.png");
  } else if (index === 2) {
    items.value[2].image = require("./assets/images/agencies.png");
    showQrCode.value = false;
  } else if (index === 3) {
    items.value[3].image = require("./assets/images/top.png");
  } else if (index === 4) {
    items.value[4].image = require("./assets/images/goHome.png");
    visible.value = false;
  }
};

// top
const handleClick = (index) => {
  if (index === 3) {
    window.scrollTo(0, 0);
  } else if (index === 4) {
    if (route.path !== "/") {
      // 如果不是首页，则返回上一页
      router.back();
    }
  }
};
onMounted(() => {
  if (route.path !== "/") {
    isIndexFourHidden.value = true;
  }
  window.addEventListener("scroll", updateScrollPosition);
  watchEffect(updateNavigation); // 当scrollPosition变化时，更新showNavigation
});
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}

#app {
  // font-family: Avenir, Helvetica, Alibaba PuHuiTi, sans-serif;
  // font-family: Alibaba-PuHuiTi;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: right;
  align-items: center;
  color: #fff;
}

body {
  width: 100%;
  margin: 0;
  // background-color: blue;
 // @font-face {
    //font-family: Alibaba-PuHuiTi_bold;
    //src: url("/src/assets/font/Alibaba-PuHuiTi-Medium.ttf");
 //}
  @font-face {
    font-family: Alibaba-PuHuiTi;
    src: url("/src/assets/font/Alibaba-PuHuiTi-Bold.ttf");
  }
  //@font-face {
   // font-family: Alibaba-PuHuiTi_Rg;
    //src: url("/src/assets/font/Alibaba-PuHuiTi-Regular.ttf");
  //}
}

.el-tooltip__trigger {
  outline: none;
}

.jt::before {
  content: "";
  width: 8px;
  height: 8px;
  border: solid #fff;
  border-width: 0 0 2px 2px;
  position: absolute;
  right: -10px;
  top: 24%;
  transform: rotate(-45deg);
}

.jt {
  // float: left;
  position: relative;
  margin-right: 20px;
  font-size: 18px;
  font-weight: 700;
  margin-top: -2px;
  padding: 0 10px;
}

.modal {
  background: linear-gradient(
    180deg,
    rgba(46, 46, 46, 0.7) 0%,
    rgba(23, 23, 23, 0.2) 100%
  );
  position: absolute;
  left: 0;
  padding: 0 25px 35px 30px;
  margin-top: 17px;

  ul {
    li {
      font-size: 18px;
      font-weight: 500;
      color: rgba(229, 229, 229, 1);
      text-align: left;
      padding-top: 20px;
      list-style-type: disc;
    }
  }
}

.modal::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 50%;
  border: 10px solid transparent;
  border-bottom: 10px solid #464242;
  /* 调整三角的大小和位置 */
}

.howin-menu-button {
  position: fixed;
  top: 140px;
  left: 25px;
  width: 30px;
  height: 22px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  z-index: 999999;

  span {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: #c4c4c4;
  }
}

// html {
//   font-size: 100px;
// }
.all_icon {
  height: 100px;
  width: 100px;
  position: fixed;
  right: 0;
  bottom: 100px;
  z-index: 99999999;
}

.tc {
  position: fixed;
  height: 100%;
  width: 99vw;
  z-index: 999;
  background: rgba(0, 0, 0, 0.4);

  .fk {
    font-family: Alibaba-PuHuiTi_Rg;
    width: 350px;
    height: 260px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    z-index: 999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-weight: 500;
    line-height: 19.21px;
    color: rgba(23, 23, 23, 1);
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
      padding: 40px 0 17px 0;
    }
  }
}

.right_navigation:nth-child(3) {
  position: relative;
  // 二维码
  .square {
    width: 180px;
    height: 200px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    z-index: 10;
    position: relative;
  }

  .square::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid rgba(255, 255, 255, 1);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
  }

  .wx {
    width: 120px;
    height: 120px;
    background: url("./assets/images/wx.png") no-repeat center;
    background-size: cover;
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translate(-50%);
  }
}

// 右侧导航
.navigation {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;

  .right_navigation {
    margin-top: 2px;
    width: 100px;
    height: 100px;
    background: linear-gradient(
      134.89deg,
      rgba(157, 247, 252, 1) 0%,
      rgba(61, 205, 231, 1) 100%
    );
    color: rgba(255, 255, 255, 1);
    position: relative;

    // text-align: center;
    .pic {
      width: 36px;
      height: 36px;
      position: absolute;
      top: 21px;
      left: 45%;
      transform: translateX(-50%);
      img {
        width: 48px;
        height: 48px;
        background-size: 100%;
      }
    }
  }
}

.howin-jr {
  width: 100%;
  padding: 80px 0 180px 0;
  background: url(./assets/images/howin-jr-bg.png) no-repeat center;
  background-size: 100%;

  h1 {
    width: 100%;
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 47px;
    text-align: center;
  }

  h3 {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: center;
    margin-top: 20px;
  }

  .howin-jr-Div {
    position: relative;
    width: 100%;
    height: 46px;
    min-width: 360px;
    max-width: 370px;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 40px;

    .pop {
      height: 46px;
      position: absolute;
      left: 20%;
      background-color: #fff;
    }

    img {
      position: absolute;
      top: 15px;
      left: 13px;
    }

    .howin-jr-phone {
      width: 300px;
      padding: 0 20px 0 40px;
      height: 46px;
      border-radius: 10px;
      border: 0px;
      margin-right: 0;
      box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
      background-color: #f7faff !important;
    }

    input:focus {
      outline: none;
    }

    .howin-jr-submit {
      position: absolute;
      width: 85px;
      height: 40px;
      top: 3px;
      right: 14px;
      border-radius: 10px !important;
      border: 0px;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
      color: #55bddb !important;
      background-color: #fff;
    }
  }
}

.share {
  font-family: Alibaba-PuHuiTi_Rg;
  width: 100%;
  height: 68px;
  background: rgba(255, 255, 255, 1);
  margin: 0 auto;
  text-align: right;
  line-height: 68px;
  border-top: 1px solid rgba(229, 229, 229, 1);
  img {
    cursor: pointer;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    color: rgba(166, 166, 166, 1);
  }
  .pic {
    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  .goHome {
    padding-right: 30px;
  }
  .pic:nth-child(1) {
    position: relative;
  }
  .code {
    width: 180px;
    height: 200px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    position: absolute;
    left: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.46px;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    .box {
      img {
        height: 120px !important;
        width: 120px;
        margin: 25px 0 18px 0;
      }
    }
  }
  .pic {
    display: inline-block;
    position: relative;
  }
}

.howin-footer-Div-div1 {
  font-family: Alibaba-PuHuiTi_Rg;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 38px;
  // border-bottom: 1px solid #093B85;
  // background: url(./assets/images/wzdz.png) no-repeat center;
  background-size: 100%;

  .howin-footer-Div-div1-child1 {
    padding-top: 44px;
    width: 50%;
    display: flex;
    justify-content: end;

    .howin-footer-Div-div1-child1-round {
      font-size: 20px;
      font-weight: 400;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      margin: 0 35px;
      background-image: url(./assets/images/howin-footer-round.png);
      background-size: 100%;

      span {
        font-size: 12px;
        font-weight: 400px;
      }
    }

    .howin-footer-Div-div1-child1-text {
      width: 120px;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
      margin: auto;
      margin-top: 15px;
    }
  }

  .howin-footer-Div-div1-child2 {
    width: 50%;
    padding-top: 44px;
    display: flex;
    justify-content: start;

    .howin-footer-Div-div1-child2-round {
      font-size: 20px;
      font-weight: 300;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      margin: 0 35px;
      background-image: url(./assets/images/howin-footer-round.png);
      background-size: 100%;
    }

    .howin-footer-Div-div1-child2-text {
      width: 120px;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
      margin: auto;
      margin-top: 15px;
    }
  }
}

.howin-footer-Div-div2 {
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #246c80;
  display: flex;
  justify-content: space-between;

  .howin-footer-Div-div2-child1 {
    text-align: left;
    font-family: Alibaba-PuHuiTi_Rg;

    p {
      margin-top: 48px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }

    h3 {
      margin-top: 20px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }

    h4 {
      margin-top: 27px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .howin-footer-Div-div2-child2 {
    text-align: left;
    font-family: Alibaba-PuHuiTi_Rg;

    h2 {
      margin-top: 17px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    h3 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 40px;
    }

    h4 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 10px;
    }
  }
}

.block {
  padding: 60px 0;
  display: flex;
  text-align: center;
  justify-content: center;
  background: rgba(224, 224, 224, 1);
  cursor: pointer;
  font-size: 28px;
  font-weight: 400;
  .text {
    margin: 100px 0 20px 0;
  }

  .pro {
    width: 225px;
    height: 54px;
    margin: 30px auto;
    background-size: 100%;
  }

  .arrow {
    width: 34px;
    height: 34px;
    margin: 30px auto;
    background: url("./assets/images/rt.png") no-repeat center;
    background-size: 100%;
  }

  .l_block {
    background: rgba(255, 255, 255, 1);
    width: 45%;
    // height: 370px;
    background-size: 100%;

    .pro {
      background: url("./assets/images/PROJECT (2).png") no-repeat center;
      background-size: 100%;
    }
  }

  .r_block {
    background: linear-gradient(
      270deg,
      rgba(7, 141, 236, 1) 0%,
      rgba(46, 188, 240, 1) 100%
    );
    width: 45%;
    height: 370px;
    background-size: 100%;

    .pro {
      background: url("./assets/images/PROJECT (1).png") no-repeat center;
      background-size: 100%;
    }

    .arrow {
      background: url("./assets/images/rt1.png") no-repeat center;
      background-size: 100%;
    }
  }
}

.howin-hx img {
  display: block;
}

nav {
  cursor: pointer;
  width: 100%;
  position: fixed;
  padding: 30px 0 0 30px;
  z-index: 3;

  .howin-menu {
    font-family: Alibaba-PuHuiTi_Rg;
    align-content: center;
    position: absolute;
    right: 150px;
    top: 60px;
    a {
      font-size: 18px;
      font-weight: 600;
      position: relative;
      // font-weight: lighter;
      color: #fff;
      text-decoration: none;
      padding: 0px 10px;
      margin: 0px 10px;
      line-height: 1;

      &.router-link-exact-active {
        color: rgba(110, 227, 205, 1);
      }

      &.router-link-exact-active::before {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translate(-50%);
        width: 20px;
        height: 1.5px;
        background-color: rgba(110, 227, 205, 1);
        opacity: 1;
      }
    }

    a:hover {
      // color: #3DD7FF;
      color: rgba(110, 227, 205, 1);
    }

    a:hover::before {
      content: "";
      position: absolute;
      bottom: -4px;
      // left: 18px;
      left: 50%;
      transform: translate(-50%);
      width: 20px;
      height: 1.5px;
      background-color: rgba(110, 227, 205, 1);
      opacity: 1;
    }
  }

  .menu {
    width: auto;
    padding-left: 31px;
  }
}

.howin-mz {
  text-align: center;
  margin: 140px 0 40px 0;
  font-family: Alibaba-PuHuiTi_Rg;
  a {
    color: #333333;
  }
  .white {
    color: #ffffff;
  }

  a:hover {
    color: rgba(7, 141, 236, 1);
  }
}
</style>
