import { createApp } from 'vue'
import VueAnimateOnScroll from "@/api/animate-onscroll";
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import store from './store'
import '../src/assets/css/home.css';
import countTo from 'vue3-count-to'
import vue3SeamlessScroll from "vue3-seamless-scroll";
import zhLocale from 'element-plus/es/locale/lang/zh-cn';
import * as echarts from 'echarts';
import 'echarts/theme/dark' // 引入全局主题
import 'echarts/lib/component/tooltip' // 引入全局组件
import './assets/font/font.css'
import 'amfe-flexible'//导入



const app = createApp(App);
app.config.globalProperties.$echarts = echarts // 将 ECharts 注册到 Vue 全局属性中
app.use(vue3SeamlessScroll as any);
app.use(store);
app.use(VueAnimateOnScroll);
app.use(ElementPlus, { locale: zhLocale });
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(router);
app.use(countTo)
app.mount('#app');