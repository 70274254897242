<template>
  <div class="tc" v-show="shared">
    <div class="fk">
      <img src="../assets/images/Icon.png" alt="" height="96px" />
      <div
        style="
          border-bottom: 1px rgba(241, 243, 248, 1) solid;
          padding-bottom: 31px;
        "
      >
        成功
      </div>
      <div
        style="
          font-size: 11px;
          font-weight: 400;
          line-height: 15.09px;
          color: rgba(100, 120, 211, 1);
          text-align: center;
          padding-top: 18px;
        "
      >
        恭喜您提交成功
      </div>
    </div>
  </div>
  <!-- 顶部导航 -->
  <nav v-if="Isshow">
    <div class="menu">
      <a href="/">
        <img src="../assets/images/lg.png" loading="lazy" height="105px" />
      </a>
    </div>

    <!-- <div><img src="../assets/images/" alt=""></div> -->

    <div class="howin-menu">
      <router-link to="/">首页</router-link>
      <a @click="Modal" @mouseenter="MouseEnter" ref="model">服务</a>
      <router-link to="/case">案例</router-link>
      <router-link to="/service">见证</router-link>
      <router-link to="/about">关于</router-link>
      <router-link to="/search">
        <img
          :src="imageUrl"
          loading="lazy"
          height="14px"
          style="display: inline-block; background-size: 100%"
          @mouseover="handleMouseOver"
          @mouseout="handleMouseOut"
        />
      </router-link>
      <div
        class="modal"
        v-if="showModal"
        @mouseenter="showModal = true"
        @mouseleave="showModal = false"
      >
        <ul>
          <li
            v-for="(item, index) in items"
            :key="item.id"
            :style="{
              color: isHoveredIndex === index ? 'rgba(110, 227, 205, 1)' : '',
            }"
            @click="selectItem(index)"
            @mouseenter="handleMouseEnter(index)"
            @mouseleave="handleMouseLeave(index)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="quan" v-show="showLogo">
    <!-- 外圈 -->
    <div class="logo"></div>
    <!-- 内圈 -->
    <div class="nei" style="position: relative">
      <div class="c_pic"></div>
      <div
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <div class="hy" v-animate-onscroll="hy"></div>
        <div class="content" v-animate-onscroll="content">
          专注数字化网站升级
        </div>
      </div>
    </div>
  </div>

  <div class="howin-video-container">
    <!-- <video autoplay="autoplay" loop="loop" muted>
      <source src="../assets/images/121.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video> -->
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in banners" :key="index">
          <img :src="item.pic" />
        </div>
      </div>
    </div>
    <!-- <img src="../assets/images/mounse.png" loading="lazy" /> -->
  </div>
  <!-- 80%的宽度主体 -->
  <div class="howin-main">
    <div class="howin-menu-button" v-if="isDivVisible" @click="toggleAnimation">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="howin-aside-menu-div" :class="{ animate: isAnimating }">
      <div class="howin-aside-menu">
        <span class="cross" @click="toggleAnimation">
          <span class="cross-line1"></span>
          <span class="cross-line2"></span>
        </span>
        <ul>
          <li @click="goPop(0)">首页</li>
          <li @click="goPop(1)">服务</li>
          <li @click="goPop(2)">案例</li>
          <li @click="goPop(3)">关于</li>
          <li @click="goPop(4)">搜索</li>
        </ul>
      </div>
    </div>
    <!-- 内容 -->
    <!-- 做跨境首选-骅赢数字营销 -->
    <div class="howin-sx">
      <h1>做跨境<span class="blue">首选</span>-骅赢数字营销</h1>
      <h4>致力于为全球客户提供领先的出海独立站与营销科技服务</h4>
      <div class="howin-sx-Div">
        <div class="howin-sx-Div-div1">
          <!-- 首选左边上半部分 -->
          <div class="howin-sx-Div-div1-child1">
            <div
              class="howin-sx-Div-div1-child1-sj"
              v-for="(item, index) in sjList1"
              :key="index"
            >
              <div class="howin-sx-Div-div1-child1-sj-name">
                {{ item.name }}
              </div>
              <div class="howin-sx-Div-div1-child1-sj-number">
                <countTo
                  :startVal="0"
                  :endVal="item.number"
                  :duration="3000"
                  :suffix="item.unit"
                  v-if="countNum"
                >
                </countTo>
              </div>
            </div>
          </div>
          <!-- 首选左边下半部分 -->
          <div class="howin-sx-Div-div1-child2" style="font-size: 14px">
            <div
              class="howin-sx-Div-div1-child2-icon"
              v-for="(item, index) in sjList2"
              :key="index"
            >
              <img
                src="../assets/images/howin-sx-ok.png"
                loading="lazy"
              /><span>{{ item.title }}</span>
            </div>
          </div>
        </div>
        <div class="howin-sx-Div-div2">
          <img src="../assets/images/howin-sx-earth.png" loading="lazy" />
          <!-- 光环元素 -->
          <div class="halo-div"></div>
        </div>
      </div>
    </div>
    <!-- 六大核心服务 -->
    <div class="howin-hx">
      <h1><span>6</span>大核心服务</h1>
      <h4>
        无论您的产品是面向哪种消费者、销往世界的哪一个角落，从“店”商、
        在线全球市场交易、社交媒体，到点对点个人营销，都可以帮您一一实现。
      </h4>
      <div class="howin-hx-Div" v-for="(item, index) in hxList" :key="index">
        <div class="howin-hx-Div-div howin-hx-Div-borderColor1">
          <div class="howin-hx-Div-div-img">
            <img :src="item.img" loading="lazy" />
          </div>
          <h3>{{ item.hxname }}</h3>
          <div class="howin-hx-Div-div-description">{{ item.description }}</div>
          <div class="howin-hx-Div-div-button">
            <button @click="goCustomize(index)">{{ item.button }}</button>
          </div>
        </div>
        <div class="howin-hx-Div-div howin-hx-Div-borderColor2">
          <div class="howin-hx-Div-div-img">
            <img :src="item.img1" loading="lazy" />
          </div>
          <h3>{{ item.hxname1 }}</h3>
          <div class="howin-hx-Div-div-description">
            {{ item.description1 }}
          </div>
          <div class="howin-hx-Div-div-button">
            <button class="howin-button" @click="goCustomize2(index)">
              {{ item.button1 }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 全域数字营销平台与企业数字业务平台 -->
    <div class="howin-hy" id="targetElement">
      <h1><span>全域</span>数字营销平台与企业数字业务平台</h1>
      <div class="howin-hy-menu">
        <div
          class="howin-hy-menu-type"
          :class="{ active: activeIndex === index }"
          v-for="(item, index) in typeList"
          :key="index"
          @click="setActive(index)"
        >
          {{ item }}
        </div>
      </div>
      <h5>1000+专业设计师，一对一设计方案定制，应用最前沿设计理念</h5>
      <div class="demo">
        <vue3-seamless-scroll
          :list="typeShowList"
          :hover="true"
          :limitScrollNum="3"
          direction="left"
          class="scoll"
        >
          <ul style="display: flex; gap: 30px; padding: 0; margin-right: 30px">
            <li
              v-for="(item, index) in typeShowList"
              :key="index"
              style="width: 270px; height: 400px"
            >
              <img
                :src="item"
                alt="image"
                height="100%"
                width="100%"
                loading="lazy"
              />
            </li>
          </ul>
        </vue3-seamless-scroll>
      </div>
    </div>

    <!-- 全球企业 -->
    <div class="enterprise">
      <h1 style="padding-top: 69px"><span>全球企业</span>-营销价值见证</h1>
      <div style="display: flex; justify-content: space-around">
        <!-- 左 -->
        <div class="" v-animate-onscroll="'left_bg'">
          <video
            v-if="videoLoadedl"
            ref="videoRefLeft"
            src="../assets/images/ylfm.mp4"
            poster=""
            controls
            width=""
            height=""
            @pause="pauseVideo"
            @playing="hideVideo"
          ></video>
          <img
            class="v-img"
            loading="lazy"
            v-if="showImage"
            src="../assets/images/ship.png"
          />
          <img
            class="overlay-img"
            loading="lazy"
            v-if="showImage"
            src="../assets/images/play.png"
            @click="hideImage"
          />
          <img />
        </div>
        <!-- 右 wgj-->
        <div class="" v-animate-onscroll="'right_bg'">
          <video
            v-if="videoLoadedR"
            ref="videoRefRight"
            src="../assets/images/zh.mp4"
            poster="../assets/images/right_video.png"
            controls
            width=""
            height=""
            @playing="hideVideo1"
            @pause="pauseVideo"
          ></video>
          <img class="v-img" v-if="showImage1" src="../assets/images/set.png" />
          <img
            class="overlay-img"
            v-if="showImage1"
            src="../assets/images/play.png"
            @click="hideImage1"
          />
          <img />
        </div>
      </div>
      <!-- 更多 -->
      <div class="more" @click="goWitness">
        <div>查看更多</div>
        <div class="more_pic"></div>
      </div>
    </div>
    <!-- 知名案例 -->
    <div class="howin-al">
      <h1>知名案例</h1>
      <div class="howin-al-type">
        <div class="howin-al-type-name">全部</div>
      </div>
      <div class="howin-al-Div" style="background-color: pink">
        <div
          class="howin-al-Div-div"
          v-for="(item, index) in caseList"
          :key="index"
          @click="goPage(index)"
          @mouseenter="Enter(index)"
          @mouseleave="Leave(index)"
        >
          <div class="h_box">
            <img :src="item.imgUrl" loading="lazy" height="400px" />
          </div>
          <!-- 遮罩 -->
          <div class="howin-al-Div-div-bg">
            <!-- logo -->
            <div class="al_logo">
              <div class="hz">
                <img
                  style="display: block"
                  :src="item.img"
                  alt=""
                  loading="lazy"
                />
              </div>
              <div v-show="pic == index">
                <!-- v-show="pic == index" -->
                <div
                  style="
                    font-size: 32px;
                    font-weight: 700;
                    color: rgba(255, 255, 255, 1);
                    padding-top: 17px;
                    text-align: center;
                  "
                >
                  {{ item.imgName }}
                </div>
                <div
                  style="
                    height: 32px;
                    width: 32px;
                    margin: 0 auto;
                    padding-top: 18px;
                  "
                >
                  <img
                    :src="item.Url"
                    alt=""
                    loading="lazy"
                    style="cursor: pointer"
                  />
                </div>
              </div>
            </div>
            <span href="" class="howin-al-Div-div-bg-a"> </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 立即加入 -->
    <div class="howin-jr" style="padding: 80px 0 120px 0">
      <h1>立即加入，开启自己的挖金之旅</h1>
      <h3>专业网站技术售后、客户成功顾问、助力商户优质排名</h3>
      <div class="howin-jr-Div">
        <form action="">
          <img src="../assets/images/howin-phone.png" loading="lazy" />
          <input
            type="tel"
            class="howin-jr-phone"
            maxlength="11"
            placeholder="请输入手机号"
            v-model="inputValue"
            @blur="validateInput"
          />
          <input
            type="submit"
            class="howin-jr-submit"
            value="开始建站"
            @click.prevent="submitForm"
          />
        </form>
      </div>
    </div>

    <!-- 底部footer -->
    <div class="howin-footer">
      <div class="howin-footer-Div">
        <!-- 六个圆 -->
        <div class="howin-footer-Div-div1">
          <div class="howin-footer-Div-div1-child1">
            <div v-for="(item, index) in roundList1" :key="index">
              <div class="howin-footer-Div-div1-child1-round">
                {{ item.number }}<span>{{ item.unit }}</span>
              </div>
              <div class="howin-footer-Div-div1-child1-text">
                {{ item.text }}
              </div>
            </div>
          </div>
          <div class="howin-footer-Div-div1-child2">
            <div v-for="(item, index) in roundList2" :key="index">
              <div class="howin-footer-Div-div1-child2-round">
                {{ item.number }}<span>{{ item.unit }}</span>
              </div>
              <div class="howin-footer-Div-div1-child2-text">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
        <!-- logo -->
        <div class="howin-footer-Div-div2">
          <div class="howin-footer-Div-div2-child1">
            <img src="../assets/images/lg.png" loading="lazy" height="68px" />
            <p>公司邮箱：howinsupport@google365.com.cn</p>
            <h3>公司地址：辽宁省大连市甘井子区红星国际广场</h3>
            <h4>城市分站</h4>
          </div>
          <div class="howin-footer-Div-div2-child2">
            <h2 @click="goJump()">服务项目</h2>
            <h3 @click="goJump(0)">品牌网站建设</h3>
            <h4 @click="goJump(1)">高端独立站定制</h4>
            <h4 @click="goJump(2)">谷歌店铺运营服务</h4>
            <h4 @click="goJump(3)">海外平台推广维护</h4>
            <h4 @click="goJump(4)">谷歌店铺口碑好评</h4>
            <h4 @click="goJump(5)">出海营销服务包</h4>
          </div>
          <div class="howin-footer-Div-div2-child2">
            <h2 @click="scrollToElement(0)">行业</h2>
            <h3 @click="scrollToElement(0)">外贸建站</h3>
            <h4 @click="scrollToElement(1)">工业建站</h4>
            <h4 @click="scrollToElement(2)">批发零售</h4>
            <h4 @click="scrollToElement(3)">服务业建站</h4>
          </div>
          <div class="howin-footer-Div-div2-child2">
            <h2 @click="goTerms(0)">相关条款</h2>
            <h3 @click="goTerms(1)">法律公告</h3>
            <h4 @click="goTerms(2)">隐私保护</h4>
            <h4 @click="goTerms(3)">仿冒声明</h4>
            <h4 @click="goTerms(4)">免责声明</h4>
          </div>
        </div>
      </div>
      <!-- 免责 -->
      <div class="howin-mz">
        <a href="" style="text-decoration: none"> 骅赢科技 </a>
        <span> 版权所有 </span>
        <a
          href="https://lnca.miit.gov.cn/"
          target="_blank"
          style="text-decoration: none"
          >ICP备案/许可证号:辽ICP备2024033208号-1</a
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watchEffect, computed } from "vue";
import { CountTo } from "vue3-count-to";
import { useRouter } from "vue-router";

// import {vue3ScrollSeamless} from "vue3-scroll-seamless";
// import CarouselView from '../components/CarouselView.vue'
import { ElMessage } from "element-plus";
import imageSource from "../assets/images/bs.png";
import imageSource1 from "../assets/images/ls.png";
import { reactive } from "vue";
import Swiper from "swiper"; // 引入库
import "swiper/css/swiper.css"; // 引入样式文件 注意5和6版本的样式文件不一致

const imageUrl = ref(imageSource);

const pic = ref(-1);
const Enter = (index) => {
  pic.value = index;
};
const Leave = (index) => {
  pic.value = -1;
};
const isHoveredIndex = ref(-1);
const handleMouseEnter = (index) => {
  isHoveredIndex.value = index;
};
const handleMouseLeave = () => {
  isHoveredIndex.value = -1;
};
const showModal = ref(false);
const items = ref([
  { name: "品牌网站建设" },
  { name: "高端独立站定制" },
  { name: "谷歌店铺运营服务" },
  { name: "海外平台推广维护" },
  { name: "谷歌店铺口碑好评" },
  { name: "出海营销服务包" },
]);

const Modal = () => {
  showModal.value = !showModal.value;
};
const goPop = (index) => {
  // console.log(index);
  switch (index) {
    case 0:
      router.push("/");
      toggleAnimation();
      window.scrollTo(0, 0);
      break;
    case 1:
      router.push("/service");
      toggleAnimation();
      break;
    case 2:
      router.push("/case");
      toggleAnimation();
      break;
    case 3:
      router.push("/about");
      toggleAnimation();
      break;
    case 4:
      router.push("/search");
      toggleAnimation();
      break;
    default:
  }
};
const router = useRouter();
const goCustomize = (index) => {
  // console.log(index);
  switch (index) {
    case 0:
      router.push({
        path: "/brand",
        query: {
          num: 0,
        },
      });
      break;
    case 1:
      router.push({
        path: "/shop",
        query: {
          num: 2,
        },
      });
      break;
    case 2:
      router.push({
        path: "/acclaim",
        query: {
          num: 4,
        },
      });
      break;
    default:
  }
};
const goCustomize2 = (index) => {
  // console.log(index);
  switch (index) {
    case 0:
      router.push({
        path: "/customize",
        query: {
          num: 1,
        },
      });
      break;
    case 1:
      router.push({
        path: "/popularize",
        query: {
          num: 3,
        },
      });
      break;
    case 2:
      router.push({
        path: "/sea",
        query: {
          num: 5,
        },
      });
      break;
    default:
  }
};

const selectItem = (index) => {
  // console.log(index);
  switch (index) {
    case 0:
      router.push("/brand");
      break;
    case 1:
      router.push("/customize");
      break;
    case 2:
      router.push("/shop");
      break;
    case 3:
      router.push("/popularize");
      break;
    case 4:
      router.push("/acclaim");
      break;
    case 5:
      router.push("/sea");
      break;
    default:
  }
};
const model = ref(null);
const MouseEnter = () => {
  showModal.value = true;
};
function handleMouseOver() {
  imageUrl.value = imageSource1;
}

function handleMouseOut() {
  // 鼠标悬停结束时还原图片地址
  imageUrl.value = imageSource;
}
const shared = ref(false);

const submitForm = () => {
  // console.log(inputValue.value, "按钮");
  if (inputValue.value == "") {
    ElMessage({
      message: "输入内容不能为空",
      type: "warning",
    });
  } else {
    // shared.value = true;
    // setTimeout(() => {
    //   shared.value = false;
    // }, 3000);
    // inputValue.value = "";
  }
};
// 验证输入是否为纯数字
const validateInput = (e) => {
  const value = e.target.value;
  if (e.target.value == "") {
    // console.log("正确");
  } else if (!/^1[3-9][0-9]{9}$/.test(value) || value == "") {
    // console.log("错误");
    ElMessage({
      message: "请输入中国大陆手机号",
      type: "warning",
    });
  } else {
    shared.value = true;
    setTimeout(() => {
      shared.value = false;
    }, 3000);
    inputValue.value = "";
  }
};

function scrollToElement(index) {
  const target = document.getElementById("targetElement");
  target.scrollIntoView({ behavior: "smooth" });
  activeIndex.value = index;
  if (index == 0) {
    // console.log(index, "0");
    typeShowList.value = typePicList1.value;
  } else if (index == 1) {
    // console.log(index, "1");
    typeShowList.value = typePicList2.value;
  } else if (index == 2) {
    // console.log(index, "2");
    typeShowList.value = typePicList3.value;
  } else if (index == 3) {
    // console.log(index, "3");
    typeShowList.value = typePicList4.value;
  }
}

const goWitness = () => {
  router.push("/witness");
};

const goPage = (index) => {
  router.push({
    path: "/casespage",
    query: {
      num: index,
    },
  });
};
const goJump = (index) => {
  switch (index) {
    case 0:
      router.push({
        path: "/brand",
      });
      break;
    case 1:
      router.push({
        path: "/customize",
      });
      break;
    case 2:
      router.push({
        path: "/shop",
      });
      break;
    case 3:
      router.push({
        path: "/popularize",
      });
      break;
    case 4:
      router.push({
        path: "/acclaim",
      });
      break;
    case 5:
      router.push({
        path: "/sea",
      });
      break;
    default:
  }
};
const goTerms = (index) => {
  switch (index) {
    case 0:
      router.push({
        path: "/terms",
        query: {
          num: 0,
        },
      });
      break;
    case 1:
      router.push({
        path: "/terms",
        query: {
          num: 0,
        },
      });
      break;
    case 2:
      router.push({
        path: "/terms",
        query: {
          num: 1,
        },
      });
      break;
    case 3:
      router.push({
        path: "/terms",
        query: {
          num: 2,
        },
      });
      break;
    case 4:
      router.push({
        path: "/terms",
        query: {
          num: 3,
        },
      });
      break;
    default:
  }
};

//轮播图
const banners = reactive([
  { pic: require("@/assets/images/banner1.jpg") },
  { pic: require("@/assets/images/banner2.jpg") },
  { pic: require("@/assets/images/banner3.jpg") },
  { pic: require("@/assets/images/banner4.jpg") },
]);

const goCase = (index) => {
  // console.log(123);
  switch (index) {
    case 0:
      router.push({
        path: "/casespage",
      });
      break;
    case 1:
      router.push({
        path: "/casespage",
      });
      break;
    case 2:
      router.push({
        path: "/casespage",
      });
      break;
    case 3:
      router.push({
        path: "/casespage",
      });
      break;
    case 4:
      router.push({
        path: "/casespage",
      });
      break;
    case 5:
      router.push({});
      break;
    default:
  }
};
onMounted(() => {
  window.addEventListener("scroll", checkScroll);
  window.addEventListener("scroll", showScroll);
  window.addEventListener("scroll", updateScrollLogo);
  watchEffect(updateLogo);
  //轮播图
  const mySwiper = new Swiper(".swiper-container", {
    // loop: true, // 循环模式选项
    // autoplay: true, //自动滚动
    effect: "fade", //渐变切换
    // autoplay: {
    //   delay: 3000, //3秒切换一次
    //   pauseOnMouseEnter: true, //鼠标移入 轮播暂停
    // },
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    observer: true, // 监听DOM变化
    observeParents: true, // 监听父元素变化
  });
});

//  logo
const showLogo = ref(true); // 控制导航显示
const scrollLogo = ref(0); // 存储滚动位置
// 更新滚动位置的函数
const updateScrollLogo = () => {
  scrollLogo.value = window.pageYOffset || document.documentElement.scrollTop;
};
// 根据位置更新状态
const updateLogo = () => {
  const t = 100; // 假设.section1的顶部偏移量是500px
  showLogo.value = scrollLogo.value <= t;
};
// 视频
const videoLoadedl = ref(false);
const videoLoadedR = ref(false);
const showVideo = ref(false);
//  点击播放
const videoRefLeft = ref(null); //左
const videoRefRight = ref(null); //右
const showImage = ref(true);
const showImage1 = ref(true);

const hideImage = () => {
  videoLoadedl.value = true;
  videoLoadedR.value = true;
  showVideo;
  showImage.value = false;
  setTimeout(() => {
    videoRefLeft.value.play();
    videoRefRight.value.pause();
  }, 1000);
};

const hideImage1 = () => {
  videoLoadedR.value = true;
  videoLoadedl.value = true;
  showImage1.value = false;
  setTimeout(() => {
    videoRefRight.value.play();
    videoRefLeft.value.pause();
  }, 1000);
};
// 暂停
const hideVideo = () => {
  videoLoadedR.value = true;
  videoLoadedl.value = true;
  videoRefRight.value.pause();
};

const hideVideo1 = () => {
  videoLoadedR.value = true;
  videoLoadedl.value = true;
  videoRefLeft.value.pause();
};
const pauseVideo = (ev) => {
  videoLoadedR.value = true;
  videoLoadedl.value = true;
  console.log(ev.isTrusted, "暂停");
};
// 顶部导航栏滚动
const Isshow = ref(true);
const IsshowDivAt = 150; // 滚动到距离顶部150px时显示div
function showScroll() {
  Isshow.value = window.scrollY <= IsshowDivAt;
}

// 在页面卸载时移除事件监听器
onUnmounted(() => {
  window.removeEventListener("scroll", checkScroll);
  window.removeEventListener("scroll", showScroll);
  window.removeEventListener("scroll", showLogo);
});
const rightList = ref([{}, {}]);

const sjList1 = ref([
  { name: "技术统计", number: 19, unit: "年+" },
  { name: "用户", number: 12000, unit: "+" },
  { name: "覆盖国家", number: 45, unit: "+" },
  { name: "行业细分", number: 50, unit: "+" },
]);
const sjList2 = ref([
  { title: "各国家的排名优化" },
  { title: "多语言、多国家、多种支付" },
  { title: "高端网站建设、软件定制" },
]);
const hxList = ref([
  {
    img: require("../assets/images/howin-hx-pic1.png"),
    hxname: "品牌网站建设",
    description:
      "全新呈现形式，打造深入人心的互联网企业官网\n差异化思维创造独特的品牌企业官网\n帮助全球500强企业突破数字营销瓶颈实现获客大幅增长",
    button: "立即建站",
    img1: require("../assets/images/howin-hx-pic2.png"),
    hxname1: "高端独立站定制",
    description1:
      "多对一专属定制，专注高端网站定制\n大气视觉设计，升级品牌形象，访客识别，数据监测\n满足企业对设计、交互、功能等高阶网站需求，打造极致用户体验。",
    button1: "开启定制",
  },
  {
    img: require("../assets/images/howin-hx-pic3.png"),
    hxname: "谷歌店铺运营服务",
    description:
      "主力产品转化，品牌出海\n引爆流量，链接全球用户\n谷歌在全球搜索引擎中份额达78.23%\n助力店铺站在流量巨人肩上做营销",
    button: "运营咨询",
    img1: require("../assets/images/howin-hx-pic4.png"),
    hxname1: "海外平台推广维护",
    description1:
      "用社交化营销方式，让客户成为忠实粉丝，\n增加询盘，提升品牌海外知名度\n利用Google搜索全面性，全面提升曝光度、流量及转化",
    button1: "维护咨询",
  },
  {
    img: require("../assets/images/howin-hx-pic5.png"),
    hxname: "谷歌店铺口碑好评",
    description:
      "塑造店铺的品牌形象，提升产品价值，\n高品质的好评能构建店铺与用户连接的信任感。\n有价值且主题明确的评语使访客迫不及待的点击和购物，\n给店铺带来转化率。",
    button: "店铺服务",
    img1: require("../assets/images/howin-hx-pic6.png"),
    hxname1: "出海营销服务包",
    description1:
      "全球主流媒体资源全覆盖，\n为客户在出海营销领域提供定制化的全面服务。\n及时响应客户需求，海量智库分享，专业的行业洞察，\n利用粉丝经济 同时实现曝光与销售。",
    button1: "营销服务",
  },
]);
const typeList = ref(["外贸建站", "工业建站", "批发零售", "服务业建站"]);
// 默认显示
const typeShowList = ref([
  require("../assets/images/1-1.png"),
  require("../assets/images/1-2.png"),
  require("../assets/images/1-3.png"),
  require("../assets/images/1-4.png"),
  require("../assets/images/1-5.png"),
  require("../assets/images/1-6.png"),
]);

// 24图
const typePicList1 = ref([
  require("../assets/images/1-1.png"),
  require("../assets/images/1-2.png"),
  require("../assets/images/1-3.png"),
  require("../assets/images/1-4.png"),
  require("../assets/images/1-5.png"),
  require("../assets/images/1-6.png"),
]);
const typePicList2 = ref([
  require("../assets/images/2-1.png"),
  require("../assets/images/2-2.png"),
  require("../assets/images/2-3.png"),
  require("../assets/images/2-4.png"),
  require("../assets/images/2-5.png"),
  require("../assets/images/2-6.png"),
]);
const typePicList3 = ref([
  require("../assets/images/3-1.png"),
  require("../assets/images/3-2.png"),
  require("../assets/images/3-3.png"),
  require("../assets/images/3-4.png"),
  require("../assets/images/3-5.png"),
  require("../assets/images/3-6.png"),
]);
const typePicList4 = ref([
  require("../assets/images/4-1.png"),
  require("../assets/images/4-2.png"),
  require("../assets/images/4-3.png"),
  require("../assets/images/4-4.png"),
  require("../assets/images/4-5.png"),
  require("../assets/images/4-6.png"),
]);

const activeIndex = ref(0);
const setActive = (index) => {
  // console.log(index);
  activeIndex.value = index;
  if (index == 0) {
    // console.log(index, "0");
    typeShowList.value = typePicList1.value;
  } else if (index == 1) {
    // console.log(index, "1");
    typeShowList.value = typePicList2.value;
  } else if (index == 2) {
    // console.log(index, "2");
    typeShowList.value = typePicList3.value;
  } else if (index == 3) {
    // console.log(index, "3");
    typeShowList.value = typePicList4.value;
  }
};
// 立即建站电话号码
const inputValue = ref("");
const errorMsg = ref("");
const caseList = ref([
  {
    imgUrl: require("../assets/images/nr10.png"),
    imgName: "英国通用能源有限公司",
    img: require("../assets/images/c_logo.png"),
    Url: require("../assets/images/jump.png"),
  },
  {
    imgUrl: require("../assets/images/nr9.png"),
    imgName: "悟空网校",
    img: require("../assets/images/lg6.png"),
    Url: require("../assets/images/jump.png"),
  },
  {
    imgUrl: require("../assets/images/nr7.png"),
    imgName: "卓聪（上海）环保科技发展有限公司",
    img: require("../assets/images/lg1.png"),
    Url: require("../assets/images/jump.png"),
  },
  {
    imgUrl: require("../assets/images/nr3.png"),
    imgName: "三洋化成精细化学品（南通）有限公司",
    img: require("../assets/images/gl2.png"),
    Url: require("../assets/images/jump.png"),
  },
  {
    imgUrl: require("../assets/images/nr2.png"),
    imgName: "无锡中微爱芯电子有限公司",
    img: require("../assets/images/lg3.png"),
    Url: require("../assets/images/jump.png"),
  },
  {
    imgUrl: require("../assets/images/nr1.png"),
    imgName: "江苏立导科技有限公司",
    img: require("../assets/images/164.png"),
    Url: require("../assets/images/jump.png"),
  },
  {
    imgUrl: require("../assets/images/nr6.png"),
    imgName: "北京容联易通信息技术有限公司",
    img: require("../assets/images/lg4.png"),
    Url: require("../assets/images/jump.png"),
  },
  {
    imgUrl: require("../assets/images/nr5.png"),
    imgName: "苏州海博赛思生物科技有限公司 ",
    img: require("../assets/images/lg5.png"),
    Url: require("../assets/images/jump.png"),
  },
  {
    imgUrl: require("../assets/images/nrt.png"),
    imgName: "特斯拉（上海）有限公司",
    img: require("../assets/images/lg8.png"),
    Url: require("../assets/images/jump.png"),
  },
]);

const displayedImages = ref([]);

// 导航栏滚动
const isDivVisible = ref(false);
const countNum = ref(false);
const showDivAt = window.innerHeight; // 滚动到距离顶部200px时显示div
const countNumDivAt = 400; // 滚动到距离顶部200px时显示div
function checkScroll() {
  isDivVisible.value = window.scrollY >= showDivAt;
  countNum.value = window.scrollY >= countNumDivAt;
}

// 在页面卸载时移除事件监听器
onUnmounted(() => {
  window.removeEventListener("scroll", checkScroll);
  window.removeEventListener("scroll", updateScrollLogo);
});
// menu左进入
const isAnimating = ref(false);

// 切换动画状态的函数
function toggleAnimation() {
  isAnimating.value = !isAnimating.value;
}

const roundList1 = ref([
  { number: "5×8", unit: "", text: "全球售后支持" },
  { number: "24", unit: "H", text: "故障快速响应" },
  { number: "19", unit: "年", text: "19年行业服务经验" },
]);
const roundList2 = ref([
  { number: "12000", unit: "", text: "服务企业客户1.2万家" },
  { number: "100", unit: "", text: "超百人设计研发团队" },
  { number: "45", unit: "", text: "45种国家语言支持" },
]);
</script>


<style lang="less" scoped>
@import "../assets/css/home.css";
.scoll {
  overflow: hidden;
}
// body.position-fixed {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   overflow: hidden;
//   transform: translateZ(0);
// }
.howin-footer-Div-div2-child2 h3:hover,
.howin-footer-Div-div2-child2 h4:hover {
  font-size: 16px;
  color: #6ee3cd;
  cursor: pointer;
}

nav {
  background: linear-gradient(180deg, rgba(38, 38, 38, 1), rgba(82, 82, 82, 0));
}
//轮播图css

.swiper-container {
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  .swiper-slide {
    width: 100% !important;
    img {
      width: 100%;
      // height: 100%;
      object-fit: cover;
    }
  }
}
.howin-video-container {
  transform: translateZ(0);
}
</style>